@charset "utf-8";

h1 {
    color: #000080;
    font-weight: bold;
    text-align: center;
}

h2 {
    color: #000080;
    font-weight: bold;
    border-bottom: dotted 1px #000080;
    margin-top: 1em;
}

h3 {
    color: #000080;
    border-bottom: dotted 1px #000080;
    margin-top: 1em;
}

p.lead {
    padding: 1em;
    background: #c0c0ff;
}

p.footnote {
    font-size: xx-small;
    padding: 1em;
    background: #d0d0d0;
}

p.sourcecaption {
    font-weight: bold;
    text-align: center;
}
p.imagecaption {
    font-weight: bold;
    text-align: center;
}
p.listcaption {
    font-weight: bold;
    text-align: center;
}
p.emlistcaption {
    font-weight: bold;
    text-align: center;
}
p.tablecaption {
    font-weight: bold;
    text-align: center;
}
p.notecaption {
    font-weight: bold;
    text-align: center;
}

img {
    text-align: center;
}

table {
    border: solid 1px #000000;
    width: 90%;
    margin-bottom: 1em;
}

th {
    border-bottom: solid 1px #000000;
    background: #800000;
    color: #ffffff;
}

span.kw {
    font-weight: bold;
}

span.balloon {
    font-size: 0.9em;
}
span.balloon:before {
    content: "←";
}

div.column {
    padding: 0.5em;
    background: #ffd0d0;
    border: dotted 2px #808080;
}

div.image {
  text-align: center;
}

div h5 {
    font-size: larger;
    border-bottom: solid 1px #000000;
}

p {
    text-indent: 1em;
}

p.caption {
    font-weight: bold;
    text-indent: 0em;
}

p.noindent {
    text-indent: 0em;
}

p.flushright {
    text-align: right;
}

/**
 * from Rouge
 */
.highlight table td { padding: 5px; }
.highlight table pre { margin: 0; }
.highlight .cm {
  color: #999988;
  font-style: italic;
}
.highlight .cp {
  color: #999999;
  font-weight: bold;
}
.highlight .c1 {
  color: #999988;
  font-style: italic;
}
.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic;
}
.highlight .c, .highlight .cd {
  color: #999988;
  font-style: italic;
}
.highlight .err {
  color: #a61717;
  background-color: #e3d2d2;
}
.highlight .gd {
  color: #000000;
  background-color: #ffdddd;
}
.highlight .ge {
  color: #000000;
  font-style: italic;
}
.highlight .gr {
  color: #aa0000;
}
.highlight .gh {
  color: #999999;
}
.highlight .gi {
  color: #000000;
  background-color: #ddffdd;
}
.highlight .go {
  color: #888888;
}
.highlight .gp {
  color: #555555;
}
.highlight .gs {
  font-weight: bold;
}
.highlight .gu {
  color: #aaaaaa;
}
.highlight .gt {
  color: #aa0000;
}
.highlight .kc {
  color: #000000;
  font-weight: bold;
}
.highlight .kd {
  color: #000000;
  font-weight: bold;
}
.highlight .kn {
  color: #000000;
  font-weight: bold;
}
.highlight .kp {
  color: #000000;
  font-weight: bold;
}
.highlight .kr {
  color: #000000;
  font-weight: bold;
}
.highlight .kt {
  color: #445588;
  font-weight: bold;
}
.highlight .k, .highlight .kv {
  color: #000000;
  font-weight: bold;
}
.highlight .mf {
  color: #009999;
}
.highlight .mh {
  color: #009999;
}
.highlight .il {
  color: #009999;
}
.highlight .mi {
  color: #009999;
}
.highlight .mo {
  color: #009999;
}
.highlight .m, .highlight .mb, .highlight .mx {
  color: #009999;
}
.highlight .sb {
  color: #d14;
}
.highlight .sc {
  color: #d14;
}
.highlight .sd {
  color: #d14;
}
.highlight .s2 {
  color: #d14;
}
.highlight .se {
  color: #d14;
}
.highlight .sh {
  color: #d14;
}
.highlight .si {
  color: #d14;
}
.highlight .sx {
  color: #d14;
}
.highlight .sr {
  color: #009926;
}
.highlight .s1 {
  color: #d14;
}
.highlight .ss {
  color: #990073;
}
.highlight .s {
  color: #d14;
}
.highlight .na {
  color: #008080;
}
.highlight .bp {
  color: #999999;
}
.highlight .nb {
  color: #0086B3;
}
.highlight .nc {
  color: #445588;
  font-weight: bold;
}
.highlight .no {
  color: #008080;
}
.highlight .nd {
  color: #3c5d5d;
  font-weight: bold;
}
.highlight .ni {
  color: #800080;
}
.highlight .ne {
  color: #990000;
  font-weight: bold;
}
.highlight .nf {
  color: #990000;
  font-weight: bold;
}
.highlight .nl {
  color: #990000;
  font-weight: bold;
}
.highlight .nn {
  color: #555555;
}
.highlight .nt {
  color: #000080;
}
.highlight .vc {
  color: #008080;
}
.highlight .vg {
  color: #008080;
}
.highlight .vi {
  color: #008080;
}
.highlight .nv {
  color: #008080;
}
.highlight .ow {
  color: #000000;
  font-weight: bold;
}
.highlight .o {
  color: #000000;
  font-weight: bold;
}
.highlight .w {
  color: #bbbbbb;
}
.highlight {
  background-color: #f8f8f8;
}
.rouge-table { border-spacing: 0 }
.rouge-gutter { text-align: right }

/**
 * from EBPAJ EPUB 3 File Creation Guide sample style
 *
 * cf. http://ebpaj.jp/counsel/guide
 */

/* image width definition(pacentage)  */
.width-010per { width:  10%; }
.width-020per { width:  20%; }
.width-025per { width:  25%; }
.width-030per { width:  30%; }
.width-033per { width:  33%; }
.width-040per { width:  40%; }
.width-050per { width:  50%; }
.width-060per { width:  60%; }
.width-067per { width:  67%; }
.width-070per { width:  70%; }
.width-075per { width:  75%; }
.width-080per { width:  80%; }
.width-090per { width:  90%; }
.width-100per { width: 100%; }

.tcy {
  -webkit-text-combine:         horizontal;
  -webkit-text-combine-upright: all;
  text-combine-upright:         all;
  -epub-text-combine:           horizontal;
}

upright-1 {
  -webkit-text-combine:         horizontal;
  -webkit-text-combine-upright: all;
  text-combine-upright:         all;
  -epub-text-combine:           horizontal;
}
.upright {
  -webkit-text-orientation: upright;
  -epub-text-orientation:   upright;
}
